<template>
    <div style="background:#fff;height:100vh;" class="">
        
        <van-cell-group>
            <van-field v-model="value" label-width="50" label="url：" placeholder="请输入url" />
        </van-cell-group>
        <br>
        <van-button type="primary" @click="init">点击</van-button>
        <br>
        <div class="content-info p_20">
            <div id="topicMainHtml">
                <div class="content-info-box" >
                    <div class=" pl_16 pr_16 tibox ta_l">
                        <template v-for="(item,n) in topicData">
                            <span :key="n" v-if="item[0]=='str'" class="item">{{item[1]}}</span>
                            <span :key="n" v-else-if="item[0]=='tex'" class="tex_item" :id="'tex_item_'+n"></span>
                        </template>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                value:'',
                topicData: []
            }
        },
        methods: {
            init() {
                let topicData = eval(this.value);
                this.topicData = topicData;
                this.$nextTick(()=>{
                    topicData.map((e,n)=>{
                        if(e[0]=='tex'){
                            let dom = document.getElementById('tex_item_'+n);
                            this.$kx.render(e[1], dom, {
                                displayMode: true,
                            });
                        }
                    })
                })
            }
        },
    }
</script>

<style lang="less" scoped>

</style>